@import '~antd/dist/antd.css';

.ant-input {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  height: 45px;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #828282;
}

.ant-input-number-input {
  background: #FFFFFF;
  /*border: 1px solid #E0E0E0;*/
  box-sizing: border-box;
  border-radius: 5px;
  height: 45px;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #828282;
}

.ant-input-password {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  height: 45px;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #828282;
  padding-top: 0px;
  padding-bottom: 0px;
  .ant-input {
    background: transparent;
  }
}

.ant-picker {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  height: 45px;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #828282;
}

.ant-picker-input > input {
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #828282;
}

.ant-picker:hover {
  border: 1px solid #2F80ED;
}

.ant-input:hover {
  border: 1px solid #2F80ED;
}

.ant-input-number-input:hover {
  border: 1px solid #2F80ED;
}

.ant-input-password:hover {
  border: 1px solid #2F80ED;
}

.ant-input:focus, .ant-input-focused {
  box-shadow: none;
}

.ant-input-affix-wrapper {
  height: 45px;
  border-radius: 5px;
  padding-top: 0px;
  padding-bottom: 0px
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  height: 45px;
  border-radius: 5px;
  background: #FFFFFF;
  font-size: 12px;
  line-height: 19px;
  color: #828282;
  display: flex;
  align-items: center;
}

.ant-input-affix-wrapper > input.ant-input {
  height: 42px;
}

.ant-table-row {
  cursor: pointer;
}

.label {
  font-weight: normal;
  font-size: 9px;
  line-height: 14px;
  color: #828282;
}

textarea {
  height: auto !important;
}

.row-with-top-border {
  border-top: 1px solid #EBEBEB;
}

.row-with-gray-background {
  background: rgba(245, 246, 250, 0.5);
}

.ant-modal-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  color: #404040;
}

.view-label {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #404040;
}

.view-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #404040;
}

.view-interaction {
  background: #F2F3F5;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 80px;
  padding: 20px;
}

.interaction {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #707070;
}
